<template>
    <div class="account">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li><router-link to="/accounts">Accounts</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">{{ account.name === "" ? 'New Account' : account.name }}</a></li>
            </ul>
        </nav>

        <article v-if="errorMessage" class="message is-danger">
            <div class="message-header">
                <p>Save Error(s)</p>
                <button class="delete" aria-label="delete" @click="errorMessage = null"></button>
            </div>
            <div class="message-body">
                {{ errorMessage }}
            </div>
        </article>
        
        <article v-if="!accountIsActive" class="message is-warning">
            <div class="message-body">
                This account is not active and all associated lines and users are disabled.
            </div>
        </article>

        <fieldset :disabled="isLoading">
            <div class="field is-grouped">
                <div class="control is-expanded">
                    <h1 class="title">Account</h1>
                </div>
                <p class="control">
                    <button class="button is-primary" @click="saveActivePage">
                        Save
                    </button>
                </p>
            </div>

            <article v-if="!publicAccountId" class="message">
                <div class="message-body">
                    The default password for new accounts is "Textel&lt;current-year&gt;!". Ex: Textel2021!
                </div>
            </article>
            <div class="tabs">
                <ul>
                    <li :class="detailsTabActive ? 'is-active' : ''"><a @click="setDetailsTabActive()"> Details </a></li>
                    <li v-if="initialGenesysIntegrationType == null" :class="niceTabActive ? 'is-active' : ''"><a @click="setNiceTabActive()"> Nice </a></li>
                    <li v-if="!niceIntegrationLoaded" :class="genesysTabActive ? 'is-active' : ''"><a @click="setGenesysTabActive()"> Genesys </a></li>
                    <li :class="ringCentralTabActive ? 'is-active' : ''"><a @click="setRingCentralTabActive()"> RingCentral </a></li>
                    <li :class="meevoTabActive ? 'is-active' : ''"><a @click="setMeevoTabActive()"> Meevo </a></li>
                    <li :class="messageTailTabActive ? 'is-active' : ''"><a @click="setMessageTailTabActive()"> Messages </a></li>
                    <li v-if="hasPaymentsProduct" :class="paymentsTabActive ? 'is-active' : ''"><a @click="setPaymentsTabActive()"> AuthVia </a></li>
                </ul>
            </div>

            <account-details v-if="detailsTabActive"
                             ref="accountDetails"
                             :publicAccountId="publicAccountId"
                             :account="account"
                             @error="displayError" />

            <nice v-else-if="niceTabActive"
                  ref="nice"
                  :publicAccountId="publicAccountId"
                  :integrationLoaded="niceIntegrationLoaded"
                  :loadedIntegrationType="initialNiceIntegrationType"
                  @create-account-integration="createAccountIntegration"
                  @delete-account-integration="deleteAccountIntegration"
                  @error="displayError" />

            <genesys v-else-if="genesysTabActive"
                     ref="genesys"
                     :publicAccountId="publicAccountId"
                     :accountId="account.id"
                     :initialIntegrationType="initialGenesysIntegrationType"
                     @create-account-integration="createAccountIntegration"
                     @delete-account-integration="deleteAccountIntegration"
                     @error="displayError" />

            <ring-central-validation v-else-if="ringCentralTabActive"
                                     ref="ringCentral"
                                     :initialIntegrationType="initialRingCentralIntegrationType"
                                     @create-account-integration="createAccountIntegration"
                                     @delete-account-integration="deleteAccountIntegration"
                                     :publicAccountId="publicAccountId"
                                     @error="displayError" />

            <meevo v-else-if="meevoTabActive"
                ref="meevo"
                :account="account"
                @error="displayError" />

            <message-tail v-else-if="messageTailTabActive"
                          :accountLines="accountLines"
                          :accountTimezone="account.timezone"
                          @error="displayError" />

            <auth-via-merchant-settings v-else-if="paymentsTabActive"
                      ref="payments"
                      :account="account"
                      @error="displayError" />

            <div class="field is-grouped is-grouped-right mt-5">
                <p class="control">
                    <button class="button is-primary" @click="saveActivePage">
                        Save
                    </button>
                </p>
            </div>

        </fieldset>
    </div>
</template>

<script>
    import moment from 'moment-timezone';

    import { secureDelete, secureGet, securePost } from '../secureFetch.js';

    import Loading from '../components/Loading';
    import AccountDetails from '../components/AccountDetails';
    import Nice from '../components/Nice';
    import Genesys from '../components/Genesys';
    import RingCentralValidation from '../components/RingCentralValidation';
    import MessageTail from '../components/MessageTail';
    import Meevo from '../components/Meevo.vue';
    import AuthViaMerchantSettings from '../components/AuthViaMerchantSettings.vue';
    import access from '../models/access';

    export default {
        name: 'Account',
        components: {
            Loading,
            AccountDetails,
            Nice,
            Genesys,
            RingCentralValidation,
            MessageTail,
            Meevo,
            AuthViaMerchantSettings,
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            },
            timezones: function () {
                return moment.tz.zonesForCountry('US', true);
            },
            hasPaymentsProduct: function () {
                const paymentsProductId = access.productIds.Payments;
                const hasAccess = this.account?.productAccess?.some((x) => x.productId == paymentsProductId);
                return hasAccess;
            }
        },
        data() {
            return {
                isLoading: false,
                publicAccountId: null,
                accountTimezoneOffset: 0,
                account: {
                    name: "",
                    status: 1,
                    timezone: "",
                    internalSalesforceId: "",
                    integrations: [],
                    styleVersion: 1,
                },
                accountLines: [],
                integrationDictionary: { 1: 'Internal', 2: 'RingCentral', 3: 'Nice', 4: 'GenesysCCaaS', 5: 'GenesysUCaaS' },
                errorMessage: null,
                defaultErrorMessage: "Something didn't work.",
                detailsTabActive: true,
                niceTabActive: false,
                niceIntegrationLoaded: false,
                genesysTabActive: false,
                initialGenesysIntegrationType: null,
                initialNiceIntegrationType: null,
                initialRingCentralIntegrationType: null,
                ringCentralTabActive: false,
                messageTailTabActive: false,
                accountIsActive: true,
                meevoTabActive: false,
                paymentsTabActive: false,
            }
        },
        mounted() {
            this.publicAccountId = this.$route.params.publicAccountId;
            this.getAccount();
        },
        methods: {
            getAccount() {
                if (this.publicAccountId) {
                    this.isLoading = true;
                    this.isLoadingPermissions = true;

                    secureGet(this.hostUrl + "v1/account/" + this.publicAccountId)
                        .then(data => {
                            if (data && data.success) {
                                this.account = data.account;
                                this.accountIsActive = this.account.status === 1;
                                this.setTimezoneOffset(this.account.timezone);

                                if (this.account.integrations.map(x => x.integrationType).includes(3)) {
                                    this.niceIntegrationLoaded = true;
                                    this.initialNiceIntegrationType = 3;
                                }
                                else if (this.account.integrations.map(x => x.integrationType).includes(7)) {
                                    this.niceIntegrationLoaded = true;
                                    this.initialNiceIntegrationType = 7;
                                }

                                if (this.account.integrations.map(x => x.integrationType).includes(4)) {
                                    this.initialGenesysIntegrationType = "Genesys CCaaS";
                                }
                                else if ((this.account.integrations.map(x => x.integrationType)).includes(5)) {
                                    this.initialGenesysIntegrationType = "Genesys UCaaS";
                                }

                                if (this.account.integrations.map(x => x.integrationType).includes(8)) {
                                    this.initialRingCentralIntegrationType = 8;
                                }
                                else if (this.account.integrations.map(x => x.integrationType).includes(2)) {
                                    this.initialRingCentralIntegrationType = 2;
                                }

                                this.getAccountLines();
                            }

                            this.isLoading = false;
                        });
                }
            },
            setTimezoneOffset(timezoneName) {
                if (timezoneName) {
                    let timezone = this.timezones.find(x => x.name === timezoneName);

                    if (timezone) {
                        this.accountTimezoneOffset = timezone.offset;
                    }
                }
            },

            setDetailsTabActive() {
                this.detailsTabActive = true;
                this.niceTabActive = false;
                this.genesysTabActive = false;
                this.ringCentralTabActive = false;
                this.messageTailTabActive = false;
                this.meevoTabActive = false;
                this.paymentsTabActive = false;
            },
            setNiceTabActive() {
                this.detailsTabActive = false;
                this.niceTabActive = true;
                this.genesysTabActive = false;
                this.ringCentralTabActive = false;
                this.messageTailTabActive = false;
                this.meevoTabActive = false;
                this.paymentsTabActive = false;
            },
            setGenesysTabActive() {
                this.detailsTabActive = false;
                this.niceTabActive = false;
                this.genesysTabActive = true;
                this.ringCentralTabActive = false;
                this.messageTailTabActive = false;
                this.meevoTabActive = false;
                this.paymentsTabActive = false;
            },
            setRingCentralTabActive() {
                this.detailsTabActive = false;
                this.niceTabActive = false;
                this.genesysTabActive = false;
                this.ringCentralTabActive = true;
                this.messageTailTabActive = false;
                this.meevoTabActive = false;
                this.paymentsTabActive = false;
            },
            setMessageTailTabActive() {
                this.detailsTabActive = false;
                this.niceTabActive = false;
                this.genesysTabActive = false;
                this.ringCentralTabActive = false;
                this.messageTailTabActive = true;
                this.meevoTabActive = false;
                this.paymentsTabActive = false;
            },
            setMeevoTabActive() {
                this.detailsTabActive = false;
                this.niceTabActive = false;
                this.genesysTabActive = false;
                this.ringCentralTabActive = false;
                this.messageTailTabActive = false;
                this.meevoTabActive = true;
                this.paymentsTabActive = false;
            },
            setPaymentsTabActive() {
                this.detailsTabActive = false;
                this.niceTabActive = false;
                this.genesysTabActive = false;
                this.ringCentralTabActive = false;
                this.messageTailTabActive = false;
                this.meevoTabActive = false;
                this.paymentsTabActive = true;
            },  
            getAccountLines() {
                this.isLoading = true;

                secureGet(this.hostUrl + "v1/line/account-lines/" + this.publicAccountId)
                    .then((data) => {
                        if (data.success) {
                            this.accountLines = data.lines;
                        } else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }

                        this.isLoading = false;
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            saveActivePage() {
                if (this.detailsTabActive) {
                    this.$refs.accountDetails.save();
                }
                else if (this.niceTabActive) {
                    this.$refs.nice.save();
                }
                else if (this.genesysTabActive) {
                    this.$refs.genesys.save();
                }
                else if (this.meevoTabActive) {
                    this.$refs.meevo.save();
                }
                else if (this.paymentsTabActive) {
                    this.$refs.payments.save();
                }
                else if (this.ringCentralTabActive) {
                    this.$refs.ringCentral.save();
                }

                this.accountIsActive = this.account.status === 1;
            },        
            createAccountIntegration(integrationType) {
                this.isLoading = true;

                let accountIntegration = {
                    accountId: this.publicAccountId,
                    integration: integrationType
                };

                securePost(this.hostUrl + "v1/account/create-integration", accountIntegration)
                    .then(data => {
                        this.isLoading = false;

                        if (data && data.success) {
                            if (integrationType == 3 || integrationType == 7) {
                                this.niceIntegrationLoaded = true;
                                this.initialNiceIntegrationType = integrationType;
                            }
                            if (integrationType == 2 || integrationType == 8) {
                                this.initialRingCentralIntegrationType = integrationType;
                            }
                            if (integrationType == 4) {
                                this.initialGenesysIntegrationType = "Genesys CCaaS";
                            }
                            else if (integrationType == 5) {
                                this.initialGenesysIntegrationType = "Genesys UCaaS";
                            }
                        }
                        else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            deleteAccountIntegration(integrationType) {
                this.isLoading = true;

                secureDelete(this.hostUrl + "v1/account/" + this.publicAccountId + "/" + integrationType)
                    .then(data => {
                        this.isLoading = false;

                        if (data && data.success) {
                            if (integrationType == 3 || integrationType == 7) {
                                this.niceIntegrationLoaded = false;
                            }
                            if (integrationType == 4 || integrationType == 5) {
                                this.initialGenesysIntegrationType = null;
                            }
                        }
                        else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            displayError(error) {
                this.errorMessage = error;
                document.getElementById("column-right").scrollTo({ top: 0, behavior: "smooth" });
            }
        }
    };
</script>