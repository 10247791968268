<template>
    <div>
        <div class="field">
            <label class="switch-label">
                <input class="switch" type="checkbox" v-model="isEnabled" /> Enable Integration
            </label>
        </div>
        <div v-if="isEnabled">
            <div :class="integrationDropdownActive ? 'dropdown is-active' : 'dropdown'">
                <div class="dropdown-trigger">
                    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="integrationDropdownActive = !integrationDropdownActive" @blur="delayIntegrationDropdown">
                        <span>{{ integrationType }}</span>
                        <span class="icon is-small">
                            <font-awesome-icon :icon="['fa', 'angle-down']" />
                        </span>
                    </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                        <a role="button"
                           v-for="(type, index) in genesysIntegrationTypeOptions"
                           :key="index"
                           :value="type"
                           :class="integrationType == type ? 'dropdown-item is-active' : 'dropdown-item'"
                           @click="setGenesysIntegrationType(type)">
                            {{type}}
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="integrationType == 'Genesys CCaaS'" class="mt-4">
                <div class="field">
                    <label class="label">Genesys Organization Id</label>
                    <div class="control">
                        <input class="input" type="text" v-model="genesysExternalAccount.genesysOrganizationId">
                    </div>
                </div>
                <div class="field">
                    <label class="label">Client Id</label>
                    <div class="control">
                        <input class="input" type="text" v-model="genesysExternalAccount.clientId">
                    </div>
                </div>
                <div class="field">
                    <label class="label">Client Secret</label>
                    <div class="control">
                        <input class="input" type="text" v-model="genesysExternalAccount.clientSecret">
                    </div>
                </div>
                <label class="label"> Region </label>
                <div :class="regionDropdownActive ? 'dropdown is-active' : 'dropdown'">
                    <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="regionDropdownActive = !regionDropdownActive" @blur="delayRegionDropdown">
                            <span>{{ genesysExternalAccount.region }}</span>
                            <span class="icon is-small">
                                <font-awesome-icon :icon="['fa', 'angle-down']" />
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a role="button"
                               v-for="(region, index) in genesysRegionOptions"
                               :key="index"
                               :value="region"
                               :class="genesysExternalAccount.region == region ? 'dropdown-item is-active' : 'dropdown-item'"
                               @click="setGenesysRegion(region)">
                                {{region}}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="field mt-2">
                    <label class="label">Access Token</label>
                    <div class="control">
                        <input class="input" type="text" v-model="genesysExternalAccount.accessToken">
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
    import { secureGet, securePatch, securePost } from '../secureFetch.js';

    export default {
        name: 'Genesys',
        props: {
            publicAccountId: {
                type: String,
                default: null
            },
            accountId: {
                type: String,
                default: null
            },
            initialIntegrationType: {
                type: String,
                default: null
            }
        },
        computed: {
            hostUrl: function () {
                return this.$store.state.hostUrl;
            },
        },
        data() {
            return {
                genesysExternalAccount: {},
                externalAccountExists: false,
                integrationType: "Select",
                isEnabled: false,
                integrationDropdownActive: false,
                regionDropdownActive: false,
                genesysIntegrationTypeOptions: ["Select", "Genesys UCaaS", "Genesys CCaaS"],
                genesysRegionOptions: ["US_EAST_1", "US_EAST_2", "US_WEST_2", "EU_WEST_1", "EU_WEST_2", "EU_CENTRAL_1", "AP_NORTHEAST_1",
                    "AP_NORTHEAST_2", "AP_SOUTHEAST_1", "AP_SOUTH_1", "CA_CENTRAL_1", "SA_EAST_1"],
                errorMessage: null
            }
        },
        watch: {
            errorMessage(value) {
                this.$emit("error", value);
            }
        },
        mounted() {
            this.getGenesysExternalAccount();
            this.initializeGenesysExternalAccount();
        },
        methods: {
            setGenesysIntegrationType(type) {
                this.integrationType = type;
            },
            setGenesysRegion(region) {
                this.genesysExternalAccount.region = region;
            },
            delayIntegrationDropdown() {
                setTimeout(() => this.integrationDropdownActive = false, 500);
            },
            delayRegionDropdown() {
                setTimeout(() => this.regionDropdownActive = false, 500);
            },
            getGenesysExternalAccount() {
                this.isLoading = true;

                secureGet(this.hostUrl + "v1/genesys/external-account/" + this.publicAccountId)
                    .then(data => {
                        if (data && data.success) {
                            this.genesysExternalAccount = data.externalAccount;
                            this.externalAccountExists = true;
                        }

                        this.isLoading = false;
                    })
            },
            initializeGenesysExternalAccount() {
                if (this.initialIntegrationType != null) {
                    this.integrationType = this.initialIntegrationType;
                    this.isEnabled = true;
                }
                if (this.initialIntegrationType != "Genesys CCaaS") {
                    this.genesysExternalAccount.region = "Select";
                }
            },
            save() {
                this.errorMessage = null;

                // If a Genesys integration exists, disable it.
                if (this.initialIntegrationType != null && !this.isEnabled) {
                    if (this.initialIntegrationType == "Genesys CCaaS") {
                        this.$emit("delete-account-integration", 4);
                    }
                    else if (this.initialIntegrationType == "Genesys UCaaS") {
                        this.$emit("delete-account-integration", 5);
                    }
                }
                else if (this.isEnabled) {
                    // If no Genesys integration exists, create one.
                    if (this.initialIntegrationType == null) {
                        if (this.integrationType == "Genesys CCaaS") {
                            this.$emit("create-account-integration", 4);
                            if (this.externalAccountExists) {
                                this.updateGenesysExternalAccount();
                            }
                            else {
                                this.createGenesysExternalAccount();
                            }                          
                        }
                        else if (this.integrationType == "Genesys UCaaS") {
                            this.$emit("create-account-integration", 5);
                        }
                    }
                    // If a Genesys integration exists, update it.
                    else {
                        if (this.integrationType == "Genesys CCaaS") {
                            if (this.initialIntegrationType == "Genesys UCaaS") {
                                this.$emit("delete-account-integration", 5);
                                this.$emit("create-account-integration", 4);
                            }
                            if (this.externalAccountExists) {
                                this.updateGenesysExternalAccount();
                            }
                            else {
                                this.createGenesysExternalAccount();
                            }                          
                        }
                        else if (this.initialIntegrationType == "Genesys CCaaS" && this.integrationType == "Genesys UCaaS") {
                            this.$emit("delete-account-integration", 4);
                            this.$emit("create-account-integration", 5);
                        }
                    }
                }
            },
            createGenesysExternalAccount() {
                this.isLoading = true;

                const request = {
                    accountId: this.accountId,
                    genesysOrganizationId: this.genesysExternalAccount.genesysOrganizationId,
                    clientId: this.genesysExternalAccount.clientId,
                    clientSecret: this.genesysExternalAccount.clientSecret,
                    region: this.genesysExternalAccount.region,
                    accessToken: this.genesysExternalAccount.accessToken
                };

                securePost(this.hostUrl + "v1/genesys/external-account", request)
                    .then(data => {
                        this.isLoading = false;
                        if (!(data && data.success)) {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            updateGenesysExternalAccount() {
                this.isLoading = true;

                const request = {
                    id: this.genesysExternalAccount.id,
                    genesysOrganizationId: this.genesysExternalAccount.genesysOrganizationId,
                    clientId: this.genesysExternalAccount.clientId,
                    clientSecret: this.genesysExternalAccount.clientSecret,
                    region: this.genesysExternalAccount.region,
                    accessToken: this.genesysExternalAccount.accessToken
                };

                securePatch(this.hostUrl + "v1/genesys/external-account", request)
                    .then(data => {
                        this.isLoading = false;
                        if (!(data && data.success)) {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            }
        }
    };
</script>